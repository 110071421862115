import * as React from "react";
import { Wrapper, ListWrapper, ButtonWrapper,Heigh } from "./Expositions.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "./../../../components/Button";
import DondiWhite from "./../../../res/Dondi/exposition/dondi-tile.png";
import NielsShoeMeulman from "../../../res/shoe/expo/shoe-tile.png"
import Aone from "./../../../res/Aone/exposition/aone-tile.png"
import Daze from "./../../../res/Daze/exposition/daze-tile.png"
import KoolKoor from "./../../../res/KoolKoor/expo/koolkoor-tile.png"
import EventCardExpo from "../../../components/EventCardExpo";
import CoolTabs from 'react-cool-tabs';
import { useState } from "react";
import NF1 from "../../../res/Nos fantomes/fantomes-tile.png"
import PhantomV2 from "../../../res/Nos fantomes V2/fantomes-v2-tile.png"
import StudioVsVd from "../../../res/studiovsvandalism/studio-vs-vandalism-tile.png"
import MrsMoment from "../../../res/landing/tile-expo-marseille.png"
import PrsMoment from "../../../res/landing/tile-expo-paris.jpeg"
import NF2 from "../../../res/landing/winter-tile.png"
import NF3 from "../../../res/New York Street Style/cover.png"
import VIRTUAL from "../../../res/landing/virtual-visit.png"
import GDTILE from "../../../res/graffitiDynamics/tile.png"
import EPODETILE from "../../../res/epode/epode.jpg"
import RTILE from "../../../res/Reminiscence/tile.png"

const actualExhibitions = [

  // {
  //   date: "13 Octobre - 18 Novembre 2023",
  //   title: "REMINISCENCE",
  //   link: "reminiscence",
  //   artist: "",
  //   place: "Paris",
  //   description:
  //   "Ghost Galerie est heureuse de vous présenter la seconde exposition de son programme liée à la jeune scène artistique : Ghost Project. Reminiscence, qui se déroulera du 13 octobre au 18 novembre prochain, présentera le travail et l'univers de 5 artistes : Hakim Sahiri, Clémence Gbonon, Djabril Boukhenaïssi, Félix Taburet & Hannah Becquante",
  //   presentation_photo: RTILE,
  //   photos_paths: ["path", "path", "path", "path"],
  // },
];

const passedExhibitions = [


  {
    date: "13 Octobre - 18 Novembre 2023",
    title: "REMINISCENCE",
    link: "reminiscence",
    artist: "",
    place: "Paris",
    description:
    "Ghost Galerie est heureuse de vous présenter la seconde exposition de son programme liée à la jeune scène artistique : Ghost Project. Reminiscence, qui se déroulera du 13 octobre au 18 novembre prochain, présentera le travail et l'univers de 5 artistes : Hakim Sahiri, Clémence Gbonon, Djabril Boukhenaïssi, Félix Taburet & Hannah Becquante",
    presentation_photo: RTILE,
    photos_paths: ["path", "path", "path", "path"],
  },

  {
    date: "07 Avril - 03 Juin 2023",
    title: "EPODE",
    link: "epode",
    artist: "ANA MONSÓ & MATHIAS BENSIMON",
    place: "Paris",
    description:
    "Ghost Galerie a le plaisir de vous présenter sa nouvelle exposition : Épode, du 7 avril au 13 Mai 2023. \nNouveau chapitre de son histoire, cet événement s’inscrit dans un nouveau programme d’exposition - Ghost Project - mettant en lumière la scène artistique émergente contemporaine. Vernissage le 6 Avril à partir de 17h en présence des artistes.",
    // "Ghost Galerie a le plaisir de vous présenter sa nouvelle exposition : Épode, du 7 avril au 13 Mai 2023 > Prolongation jusqu'au 3 juin!. \nNouveau chapitre de son histoire, cet événement s’inscrit dans un nouveau programme d’exposition - Ghost Project - mettant en lumière la scène artistique émergente contemporaine.",
    // "La Ghost Galerie est heureuse de vous présenter son nouvel accrochage : A MOVEMENT IN MOTION. Pour ce nouvel accrochage hivernal, les oeuvres bougeront entre Paris et Marseille et inversement, Elles apparaîtrons ou disparaîtront sans prévenir ! Une double exposition qui nous permet de vous proposer entre Paris et Marseille plus d'une cinquantaine d'oeuvres des années 80 à nos jours de plusieurs artistes, Dévoilant ainsi au regard du visiteur novice ou averti, le fondement même de ce mouvement artistique : le mouvement en mouvement.",
    presentation_photo: EPODETILE,
    photos_paths: ["path", "path", "path", "path"],
  },
];

var deviceWidth = 0;
if (typeof window !== 'undefined') {
  deviceWidth = window.innerWidth;
}

// TODO 430 Plus grosse width de telephone
var test = deviceWidth < 430 ? 320 : 375;

const Expositions = () => {
  const [trueHeight, setTrueHeight] = useState(actualExhibitions.length * test)
  const [left, setLeft] = useState(true)
  const [right, setRight] = useState(false)
  // setTrueHeight([actualExhibitions.length * test]);
  
  const myFunction = () => {
    if (typeof window !== 'undefined') {
      deviceWidth = window.innerWidth;
    }
    test = deviceWidth < 430 ? 320 : 375;
    console.log("text1", test);
      setTrueHeight(passedExhibitions.length * test);
      setTrueHeight(passedExhibitions.length * test);
      console.log("first one");
      console.log(trueHeight);
      console.log(deviceWidth);
      console.log("length", passedExhibitions.length)
      console.log("text2", test);
      setLeft(false);
      setRight(true);
  }
  
  const myFunction2 = () => {
    if (typeof window !== 'undefined') {
      deviceWidth = window.innerWidth;
    }
    test = deviceWidth < 430 ? 320 : 375;
    console.log("text1", test);
    setTrueHeight(actualExhibitions.length * test)
    setTrueHeight(actualExhibitions.length * test)
    console.log("first two");
    console.log(trueHeight);
    console.log(deviceWidth);
    console.log("length", actualExhibitions.length)

    console.log("text2", test);
    setLeft(true);
    setRight(false);

  }

  return (
    <div id='expo' className={Wrapper}>
      <h1 className={GrayTitle} style={{marginTop:"60px", marginBottom:"50px"}}>EXPOSITIONS</h1>
      <CoolTabs
          tabKey={'1'}
          style={{width:  "80vw", background: 'white', height: '100%'}}
          // style={{width:  "80vw", background: 'white'}}
          activeTabStyle={{ background:  'white', color:  'black' }}
          unActiveTabStyle={{ background:  'white', color:  'black'}}
          activeLeftTabBorderBottomStyle={{ background:  'rgb(27, 27, 27)', height:  1}}
          activeRightTabBorderBottomStyle={{ background:  'rgb(27, 27, 27)', height:  1 }}
          tabsBorderBottomStyle={{ background:  'white', height:  2 }}
          leftContentStyle={{ background:  'white'}}
          rightContentStyle={{ background:  'white'}}
          leftTabTitle={<p onClick={myFunction2} style={{textAlign: "center", padding: "10px"}}>Exposition en cours</p>}
          rightTabTitle={<p onClick={myFunction} style={{textAlign: "center", padding: "10px"}}>Expositions passées</p>}
          // leftTabTitle={'Expositions du moment'}
          // rightTabTitle={'Expositions passées'}
          
          leftContent={<div className={ListWrapper}>
                    <br />
        <br />
        <br />
        <br />
            <p style={{
              fontSize: 24,
              textAlign: "center"
            }}>Rendez-vous en 2024 pour la prochaine exposition GHOST PROJECT !</p>
             <br />
        <br />
            {/* {actualExhibitions.map((exhibition, i = 0) => (
              <EventCardExpo
                date={exhibition.date}
                title={exhibition.title}
                link={"/fr-projects/expositions/" + exhibition.link}
                place={exhibition.place}
                description={exhibition.description}
                photo={exhibition.presentation_photo}
                artist={exhibition.artist}
                index={i}
                lenght={actualExhibitions.length}
              />
            ))} */}
          </div>}
          rightContent={<div className={ListWrapper}>
          {passedExhibitions.map((exhibition, i = 0) => (
            <EventCardExpo
              date={exhibition.date}
              title={exhibition.title}
              link={"/fr-projects/expositions/" + exhibition.link}
              place={exhibition.place}
              description={exhibition.description}
              photo={exhibition.presentation_photo}
              artist={exhibition.artist}
              index={i}
              lenght={passedExhibitions.length}
            />
          ))}
        </div>}
          // contentTransitionStyle={'transform 0.6s ease-in'}
          // borderTransitionStyle={'all 0.6s ease-in'}
          contentTransitionStyle={'0.8s'}
          borderTransitionStyle={'0.8s'}
      />
      {left && <div className={ListWrapper} style={{paddingTop: "10px"}}>
        <br />
        <br />
        <br />
        <br />
        <p style={{
              fontSize: 24,
              textAlign: "center"
            }}>Rendez-vous en 2024 pour la prochaine exposition GHOST PROJECT !</p>
        <br />
        <br />

            {/* {actualExhibitions.map((exhibition, i = 0) => (
              <EventCardExpo
                date={exhibition.date}
                title={exhibition.title}
                link={"/fr-projects/expositions/" + exhibition.link}
                place={exhibition.place}
                description={exhibition.description}
                photo={exhibition.presentation_photo}
                artist={exhibition.artist}
                index={i}
                lenght={actualExhibitions.length}
              />
            ))} */}
      </div>}
      {right && <div className={ListWrapper} style={{paddingTop: "10px"}}>
          {passedExhibitions.map((exhibition, i = 0) => (
            <EventCardExpo
              date={exhibition.date}
              title={exhibition.title}
              link={"/fr-projects/expositions/" + exhibition.link}
              place={exhibition.place}
              description={exhibition.description}
              photo={exhibition.presentation_photo}
              artist={exhibition.artist}
              index={i}
              lenght={passedExhibitions.length}
            />
          ))}
        </div>}
      <div className={ButtonWrapper}>
        <Button
          alt=""
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "/en/contactez-nous";
            }
          }}
        >
          Contact-us
        </Button>
      </div>
    </div>
  );
};

export default Expositions;
