import * as React from "react";
import LayoutProjects from "./../../../components/fr/LayoutProjects";
import Expositions from "./../../../nav/fr-projects/Expositions";
import { Helmet } from "react-helmet";

const ExpositionsPage = () => {
  return (
    <LayoutProjects>
      <Helmet>
        <meta charSet="utf-8" />
        <title> GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="mathiasbensimon,anamonso,ghostgalerie,parisexhibition,expositionparis,artcontemporainparis,contemporaryartgalleryparis" />
      </Helmet>
      <Expositions />
    </LayoutProjects>
  );
};

export default ExpositionsPage;
